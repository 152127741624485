import { Directive, ElementRef, HostListener, Input } from '@angular/core';
import { Util } from './util';


@Directive({
    selector: 'input[numbersDecimal]'
})
export class NumbersDecimalDirective {

    @Input() enteros: number;
    @Input() decimales: number;
    constructor(private _el: ElementRef, util:Util) { }

    @HostListener('input', ['$event']) onInputChange(event: any) {
        const initalValue = this._el.nativeElement.value;
        let val=initalValue.replace(/[^0-9.]*/g, '');
        if(!this.isEmpty(val)){
            let partes = val.split(".");
            if(partes.length>1){
                let _enteros=partes[0];
                let _decimales=partes[1];
                _enteros=_enteros.substring(0,this.enteros);
                _decimales=_decimales.substring(0,this.decimales);
                val = _enteros+"."+_decimales;
            }
        }
        this._el.nativeElement.value = val;

        if (initalValue !== this._el.nativeElement.value) {
            event.stopPropagation();
        }
    }

    public isEmpty(val) {
        return val == undefined || val == null || val == "";
    }
    

}