import { HttpHeaders } from "@angular/common/http";
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class properties {

/*
  static URL_CATALOGS : string = "http://localhost:8000/api/";
  static URL_ADMIN : string    = "http://localhost:8000/api/";
  static URL_LOGIN : string    = "http://localhost:8000/api/finduser/a379562a-4850-4857-z5e3-d758b1fca9ca";
  static URL_RH : string       = "http://localhost:8000/api/";
*/
/*
  static URL_CATALOGS : string = "https://adm.institutoifam.com/v1/api/";
  static URL_ADMIN : string    = "https://adm.institutoifam.com/v1/api/";
  static URL_LOGIN : string    = "https://adm.institutoifam.com/v1/api/finduser/a379562a-4850-4857-z5e3-d758b1fca9ca";
  static URL_RH : string       = "https://adm.institutoifam.com/v1/api/";
*/

  static URL_CATALOGS : string = "https://test.institutoifam.com/v1/api/";
  static URL_ADMIN : string    = "https://test.institutoifam.com/v1/api/";
  static URL_LOGIN : string    = "https://test.institutoifam.com/v1/api/finduser/a379562a-4850-4857-z5e3-d758b1fca9ca";
  static URL_RH : string       = "https://test.institutoifam.com/v1/api/";

  static LEVEL_MODULE = 1;
  static LEVEL_MENU = 2;
  
  static ACTIVE = 1;
  static INACTIVE = 0;
  
  static PROFILE_TYPE_PREDEFINED = 1;
  static PROFILE_TYPE_PERSONAL = 2;
  static ID_USUARIO = 1;
  
  static TIPOS_CONTRATOS = [{cve:'C1',desc:'Contrato 1',validity:1}
                           ,{cve:'C2',desc:'Contrato 2',validity:2}
                           ,{cve:'CP',desc:'Planta', validity:-1}];
  
  static PERIODOS_PAGO = [{cve:'P1',desc:'Contado'   ,numeroPagos:1}
                         ,{cve:'P2',desc:'Dos pagos' ,numeroPagos:2}
                         ,{cve:'P3',desc:'Planta'    ,numeroPagos:-1}];

  static TIPOS_GRUPO = [{cve:'PRESENCIAL',desc:'PRESENCIAL'}
                       ,{cve:'VIRTUAL',desc:'VIRTUAL'}];

  static PARENTESCOS = [{cve:'PADRE',desc:'PADRE'}
                       ,{cve:'MADRE',desc:'MADRE'}
                       ,{cve:'HERMANO(A)',desc:'HERMANO(A)'}
                       ,{cve:'ABUELO(A) PATERNO',desc:'ABUELO(A) PATERNO'}
                       ,{cve:'ABUELO(A) MATERNO',desc:'ABUELO(A) MATERNO'}
                       ,{cve:'TIO(A) MATERNO',desc:'TIO(A) MATERNO'}
                       ,{cve:'TIA(A) MATERNO',desc:'TIA(A) MATERNO'}];

  static httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json' })
  };

  static getType(type:string){
    let r = {};
    for(let i=0; i<this.TIPOS_CONTRATOS.length ; i++){
      if(this.TIPOS_CONTRATOS[i]?.cve===type){
        return r;
      }
    }
    return r;
  }


}
