import { NgModule } from '@angular/core';
import { CommonModule, DatePipe } from '@angular/common';
import { AlphanumericDirective } from '@/utils/alphanumeric-only.directive';
import { LetterDirective } from '@/utils/letters-only.directive';
import { MaxLengthDirective } from '@/utils/max-length.directive';
import { NumberDirective } from '@/utils/numbers-only.directive';
import { UppercaseDirective } from '@/utils/uppercase.directive';
import { DialogSelectorComponent } from './dialog-selector/dialog-selector.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { MatTableModule } from '@angular/material/table';
import { MatIconModule } from '@angular/material/icon';
import { MatButtonModule } from '@angular/material/button';
import { MatPaginatorModule } from '@angular/material/paginator';
import { MatSortModule } from '@angular/material/sort';
import { MatInputModule } from '@angular/material/input';
import { MatDialogModule } from '@angular/material/dialog';
import { MatSelectModule } from '@angular/material/select';
import { InputMaskModule } from 'primeng/inputmask';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatTabsModule } from '@angular/material/tabs';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { TableModule } from 'primeng/table';
import { MatCheckboxModule} from '@angular/material/checkbox';
import { NumbersDecimalDirective } from '@/utils/number-decimal.directive';

@NgModule({
  declarations: [
    AlphanumericDirective,
    LetterDirective,
    MaxLengthDirective,
    NumberDirective,
    UppercaseDirective,
    NumbersDecimalDirective,
    DialogSelectorComponent],
  imports: [
    FormsModule,
    CommonModule,
    MatTableModule,
    MatIconModule,
    MatButtonModule,
    MatPaginatorModule,
    MatSortModule,
    MatInputModule,
    MatDialogModule,
    MatSelectModule,
    InputMaskModule,
    MatFormFieldModule,
    MatTabsModule,
    MatDatepickerModule,
    TableModule,
    MatCheckboxModule
  ],
  providers: [DatePipe],
  exports: [
    AlphanumericDirective,
    LetterDirective,
    MaxLengthDirective,
    NumberDirective,
    UppercaseDirective,
    NumbersDecimalDirective,
    MatTabsModule,
    MatCheckboxModule
  ]
})
export class UtilModule { }
