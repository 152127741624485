import { Util } from '@/utils/util';
import { Component, HostListener, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { MatPaginatorIntl } from '@angular/material/paginator';
import { ActivatedRoute, NavigationEnd, Router } from '@angular/router';
import { AlumnoService } from '@services/catalogos/alumno.service';
import { PagoService } from '@services/catalogos/pago.service';
import { ProductoService } from '@services/catalogos/producto.service';
import ConectorPluginV3 from '@services/util/ConectorPluginV3';
import { NgxSpinnerService } from 'ngx-spinner';
import { Observable } from 'rxjs';
import { filter } from 'rxjs/operators';

@Component({
  selector: 'app-adm-ticket-pago',
  templateUrl: './adm-ticket-pago.component.html',
  styleUrls: ['./adm-ticket-pago.component.scss']
})
export class AdmTicketPagoComponent implements OnInit {

  folio;
  origen;
  ticket;

  previousUrl: string | undefined;
  currentUrl: string | undefined;

  @HostListener('window:afterprint')
  onafterprint() {
    this.redireccionar();
  }

  

  constructor(
    private paginator1: MatPaginatorIntl
  , private fb: FormBuilder
  , public dialog: MatDialog
  , private util: Util
  , private router:Router
  , private rutaActiva: ActivatedRoute
  , private pagoService: PagoService
  , private spinner : NgxSpinnerService
  , private alumnoService: AlumnoService
  , private productoService: ProductoService
  ){
    this.paginator1.itemsPerPageLabel = "Registros por página";
    console.log("FOLIO - "+this.rutaActiva.snapshot.params.folio);
    console.log("ORIGEN - "+this.rutaActiva.snapshot.params.origen);
    this.folio = this.rutaActiva.snapshot.params.folio;
    this.origen = this.rutaActiva.snapshot.params.origen;
    if(this.util.isEmpty(this.folio)){
      this.util.errorMessage("Ingresa un folio");
      this.redireccionar();
    }else{
      this.obtenerTicket();
    }
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      console.log(this.previousUrl);
      this.currentUrl = event.url;
    });
    
  }

  public redireccionar(){
    /*
    this.router.events
    .pipe(filter(event => event instanceof NavigationEnd))
    .subscribe((event: NavigationEnd) => {
      this.previousUrl = this.currentUrl;
      console.log(this.previousUrl);
      this.currentUrl = event.url;
      this.router.navigate([document.location.href + this.previousUrl]);
    });*/
    //this.router.navigate(["/adm/cobro/"+this.ticket?.alumno?.matricula]);
    if(this.util.isEmpty(this.rutaActiva.snapshot.params.origen)){
      this.router.navigate(["/adm/cobro/"+this.ticket?.alumno?.matricula]);
    }else{
      setTimeout(function(){
        window.history.back();
      },500);
    }
  }

  public conceptoCorto(concepto){
    return concepto.replace('Colegiatura','Cole ').replace("2024",'24');
  }
  
  obtenerTicket(){
    this.spinner.show();
    let p = {folio:this.folio};
    this.pagoService.getTicket(p).subscribe(data=>{
      this.spinner.hide();
      if(data.success){
        this.ticket = data.data;
        console.log(this.ticket);

        setTimeout(function(){
          window.print();
        },900);

      }else{
        this.util.errorMessage(data.message);
        this.redireccionar();
      }
    }, err=>{
      this.spinner.hide();
      console.log(err);
      this.redireccionar();
    });
  }
  
  
  getTotalCost() {
    let t = 0;
    if(!this.ticket){
      return 0;
    }
    for(let a=0 ; a<this.ticket.pagos_detalle.length ; a++){
      let pago = this.ticket.pagos_detalle[a];
      t+=pago.interes;
      t+=pago.precio;
    }
    return t;
  }

  ngOnInit() {
    //window.print();
  }

}
