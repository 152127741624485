import {Component, OnInit, Renderer2} from '@angular/core';
import { ApiService } from '@services/api.service';
import { AppService } from '@services/app.service';
import { ToastrService } from 'ngx-toastr';

@Component({
    selector: 'app-dashboard',
    templateUrl: './dashboard.component.html',
    styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent implements OnInit {

    
    constructor(
        private renderer: Renderer2,
        private toastr: ToastrService,
        private apiService: ApiService
    ) {}


    ngOnInit() {
        /*
        try {
            this.apiService.menu().subscribe(response=>{
                
            }, error=>{
                this.toastr.error("Credenciales invalidas");
            });
        } catch (error) {
            this.toastr.error("Credenciales invalidas");
        }*/
    }

}
